import "./teamCard.css";

const Teamcard = ({ title, name, image, height, titp, team }) => {
  // Match the part before and inside parentheses
  const match = title.match(/^(.*?)(\s*\(.*\))$/);
  const position = match ? match[1] : title; // Part before parentheses
  const department = match ? match[2] : ""; // Part inside parentheses

  return (
    <div className="focus-area-card-slim" style={{ height: titp ? height : null  }}>
      <div className="focus-area-sub-cont1-slim">
        <img className="focus-area-img-slim" src={image} alt="" />
      </div>
      {!titp && (
        <div className="focus-area-sub-cont2-slim">
          <div className="fac-sd1-slim">
            <h4 className="card-sub-title-small slim-name">{name}</h4>
            <p className="p-text-small slim-title ">{position}</p>
            <p className="p-text-small slim-title ">{department}</p>
          </div>
        </div>
      )}
    </div>
  );
};

export default Teamcard;
