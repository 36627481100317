import "./FocusAreaSection.css";
import { FocusAreaCard } from "../../../utils";
const imagePath = "/images/Banner_skilldev4.webp";

const dataArray = [
  {
    image:
      "/images/staffing.webp",
    title: "Staffing",
    description:
      "Holistic solutions for corporates in sourcing the right talent through innovative flexi-staffing",
    //wlink: "https://staffing.orionedutech.in/",
    wlink: "https://www.orioncorporate.com/",
  },
  {
    image:
      "/images/Banner_skilldev4.webp",
     // "https://res.cloudinary.com/learnhat/image/upload/v1640335600/orion-new-website/main%20home/Focus%20Area/2_pigkb6.png",
    title: "Skill Development",
    description:
      "Over 700,000 youth trained in diverse skills through government and on government skilling programs",
    link: "/skill-development",
  },
  {
    image:
      "/images/study-abroad.avif",
    title: "Study Abroad",
    description:
      "Your expert partner in your journey to study at best universities around the world",
    // Indranil: 09Dec24: link to study abroad website directly
    //link: "/study-abroad",
    wlink: "https://study-abroad.orionedutech.com/",
  },
  {
    image:
      "/images/apprenticeship.jpg",
    title: "National Apprenticeship Training Scheme",
    description:
      "Orion Edutech is dedicated to promote organisational excellence and youth empowerment.",
    //link: "/foreign-internship",
    link: "/nats",
  },
];

const FocusAreaSection = () => {
  return (
    <div className="section-container">
      <div className="section-title-cont">
        <hr className="section-title-line" />
        <h3 className="section-title">Our Work</h3>
      </div>

      <div className="card-holder">
        {dataArray.map((item) => {
          return (
            <FocusAreaCard
              image={item.image}
              title={item.title}
              wlink={item.wlink}
              link={item.link}
              description={item.description}
              height="17rem"
            />
          );
        })}
      </div>
    </div>
  );
};

export default FocusAreaSection;

// {
//   /* <div className={`focus-area-img-cont ${item.image}`}>
//   {hover === `focus-area-img-cont ${item.image}` ? (
//     <div className="hover-card">
//       <p className="hover-card-title">{item.title}</p>
//       <p className="hover-card-desc">{item.description}</p>
//       {item.wlink ? (
//         <a
//           target="_blank"
//           rel="noreferrer"
//           className="hover-card-know"
//           href={item.wlink}
//         >
//           <p>Know More</p>
//         </a>
//       ) : (
//         <Link className="hover-card-know" to={item.link}>
//           Know More
//         </Link>
//       )}
//     </div>
//   ) : (
//     <p className="card-title">{item.title}</p>
//   )}
// </div>; */
// }
